import AboutActionTypes from "./about.types";

const INITIAL_STATE = {
  about: [],
  isFetching: false,
  isLoaded: false,
  errorMessage: undefined,
};

const aboutReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case AboutActionTypes.FETCH_ABOUT_REQUEST:
      return { ...state, isFetching: true, isLoaded: false };

    case AboutActionTypes.FETCH_ABOUT_FULFILLED:
      return {
        ...state,
        about: action.payload,
        isFetching: false,
        isLoaded: true,
      };

    case AboutActionTypes.FETCH_ABOUT_REJECTED:
      return {
        ...state,
        errorMessage: action.payload,
        isFetching: false,
        isLoaded: false,
      };

    default:
      return state;
  }
};

export default aboutReducer;
