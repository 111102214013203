import { connect } from "react-redux";

const Header = (props) => {
  const { headerData, headerLoaded } = props;

  const goToSection = (e, link) => {
    if (link === "#0") e.preventDefault();

    const section = document.querySelector(
      `[data-scroll-index="${e.target.dataset.scrollNav}"]`
    );
    if (section) {
      section.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };

  const splitText = (data) => {
    const text = data.split(",").join("<br />");
    return { __html: text };
  };

  return (
    <header className="full-height valign" data-scroll-index="0">
      <div
        className="background bg-img"
        style={{ backgroundImage: "url('nft/img/pattern-bg.png')" }}
      ></div>
      <div className="container-xxl">
        <div className="row">
          <div className="col-lg-6 valign md-mb50">
            <div className="cont">
              <h1
                dangerouslySetInnerHTML={
                  headerLoaded ? splitText(headerData.title) : { __html: "" }
                }
              />
              <div className="mt-40 flex">
                <div className="mr-30">
                  <div
                    data-scroll-nav="1"
                    className="butn butn-md bg-light text-dark radius-5"
                    onClick={(e) => goToSection(e, 1)}
                  >
                    <span className="text slide-up">Explore</span>
                    <span className="text slide-down">Explore</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-5 offset-lg-1">
            {headerLoaded ? (
              <div className="flex main-marq">
                <div className="slide-vertical st1 mr-20">
                  <div className="box">
                    {headerData.st1.map((img, i) => (
                      <div className="img" key={i}>
                        <img src={img.url} alt="" />
                      </div>
                    ))}
                  </div>
                  <div className="box">
                    {headerData.st1.map((img, i) => (
                      <div className="img" key={i}>
                        <img src={img.url} alt="" />
                      </div>
                    ))}
                  </div>
                </div>
                <div className="slide-vertical st2">
                  <div className="box">
                    {headerData.st2.map((img, i) => (
                      <div className="img" key={i}>
                        <img src={img.url} alt="" />
                      </div>
                    ))}
                  </div>
                  <div className="box">
                    {headerData.st2.map((img, i) => (
                      <div className="img" key={i}>
                        <img src={img.url} alt="" />
                      </div>
                    ))}
                  </div>
                </div>
                <div className="slide-vertical st3 ml-20">
                  <div className="box">
                    {headerData.st3.map((img, i) => (
                      <div className="img" key={i}>
                        <img src={img.url} alt="" />
                      </div>
                    ))}
                  </div>
                  <div className="box">
                    {headerData.st3.map((img, i) => (
                      <div className="img" key={i}>
                        <img src={img.url} alt="" />
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            ) : (
              <div>Loading...</div>
            )}
          </div>
        </div>
      </div>
      <div className="star1">
        <img src="nft/img/header/star1.png" alt="" />
      </div>
      <div className="star2">
        <img src="nft/img/header/star2.png" alt="" />
      </div>
      <div className="star3">
        <img src="nft/img/header/star3.png" alt="" />
      </div>
      <div className="star4"></div>
    </header>
  );
};

const mapStateToProps = (state, ownProps) => {
  return {
    headerData: state.header.header[0],
    headerLoaded: state.header.isLoaded,
  };
};

export default connect(mapStateToProps)(Header);
