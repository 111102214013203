import { getAboutFromFirebase } from "../../firebase-utils/firebase-utils";
import { put, takeEvery, fork } from "redux-saga/effects";
import { getAboutSuccess, getAboutFailed } from "./about.action";
import AboutActionTypes from "./about.types";

function* fetchAboutAsync() {
  try {
    const about = getAboutFromFirebase();
    yield put(getAboutSuccess(about));
  } catch (error) {
    yield put(getAboutFailed(error.message));
  }
}

function* fetchAboutStart() {
  yield takeEvery(AboutActionTypes.FETCH_ABOUT_REQUEST, fetchAboutAsync);
}

const AboutSaga = [fork(fetchAboutStart)];

export default AboutSaga;
