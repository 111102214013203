import FooterActionTypes from "./footer.types";

export const getFooterRequest = () => ({
  type: FooterActionTypes.FETCH_FOOTER_REQUEST,
});

export const getFooterSuccess = (data) => ({
  type: FooterActionTypes.FETCH_FOOTER,
  payload: data,
});

export const getFooterFailed = (error) => ({
  type: FooterActionTypes.FETCH_FOOTER,
  payload: error,
});
