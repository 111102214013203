import { all } from "redux-saga/effects";
import categoriesSaga from "./categories/categories.saga";
import videosSaga from "./videos/videos.saga";
import headerSaga from "./header/header.saga";
import AboutSaga from "./about/about.saga";
import footerSaga from "./footer/footer.saga";

export default function* rootSagas() {
  yield all([
    ...categoriesSaga,
    ...videosSaga,
    ...headerSaga,
    ...AboutSaga,
    ...footerSaga,
  ]);
}
