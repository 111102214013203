import { useEffect, useRef } from "react";

//= Components
import { Navbar } from "../components";

const AppLayout = ({ children, links, type, navTheme }) => {
  const navbarRef = useRef(null);

  useEffect(() => {
    var navbar = navbarRef.current;

    if (window.pageYOffset > 300) navbar.classList.add("nav-scroll");
    else navbar.classList.remove("nav-scroll");

    window.addEventListener("scroll", () => {
      if (window.pageYOffset > 300) navbar.classList.add("nav-scroll");
      else navbar.classList.remove("nav-scroll");
    });
  }, [navbarRef]);

  return (
    <>
      <main>
        {/* Navbar */}
        <Navbar
          navbarRef={navbarRef}
          logoTheme={navTheme}
          theme={navTheme ? navTheme : "light"}
          links={links}
          container={type}
        />
        {/* Page Components */}
        {children}
      </main>
    </>
  );
};

export default AppLayout;
