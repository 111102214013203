import FooterActionTypes from "./footer.types";

const INITIAL_STATE = {
  footer: [],
  isFetching: false,
  isLoaded: false,
  errorMessage: undefined,
};

const FooterReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FooterActionTypes.FETCH_FOOTER_REQUEST:
      return { ...state, isFetching: true, isLoaded: false };

    case FooterActionTypes.FETCH_FOOTER_FULFILLED:
      return {
        ...state,
        footer: action.payload,
        isFetching: false,
        isLoaded: true,
      };

    case FooterActionTypes.FETCH_FOOTER_REJECTED:
      return {
        ...state,
        errorMessage: action.payload,
        isFetching: false,
        isLoaded: false,
      };

    default:
      return state;
  }
};

export default FooterReducer;
