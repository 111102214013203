import { getCategoriesFromFirebase } from "../../firebase-utils/firebase-utils";
import { put, takeEvery, fork } from "redux-saga/effects";
import { getCategoriesSuccess, getCategoriesFailed } from "./categories.action";
import CategoriesActionTypes from "./categories.types";

function* fetchCategoriesAsync() {
  try {
    const categories = getCategoriesFromFirebase();
    yield put(getCategoriesSuccess(categories));
  } catch (error) {
    yield put(getCategoriesFailed(error.message));
  }
}

function* fetchCategoriesStart() {
  yield takeEvery(
    CategoriesActionTypes.FETCH_CATEGORIES_REQUEST,
    fetchCategoriesAsync
  );
}

const categoriesSaga = [fork(fetchCategoriesStart)];

export default categoriesSaga;
