import React, { useEffect } from "react";
import { connect } from "react-redux";

import { AppLayout } from "../../layouts";
import { Header, Explore, About, Footer } from "../../components";
import { getCategoriesRequest } from "../../redux-state/categories/categories.action";
import { getVideosRequest } from "../../redux-state/videos/videos.action";
import { getHeaderRequest } from "../../redux-state/header/header.action";
import { getAboutRequest } from "../../redux-state/about/about.action";
import { getFooterRequest } from "../../redux-state/footer/footer.action";

const Home = (props) => {
  const {
    getCategoriesRequest,
    getVideosRequest,
    getHeaderRequest,
    getAboutRequest,
    getFooterRequest,
  } = props;

  useEffect(() => {
    document.body.classList.add("nft-market");

    const removeClasses = [
      "index-bus1",
      "index-corporate",
      "index-restaurant",
      "index-freelancer",
      "cr-agency",
      "mobile-app",
      "gr-orange-bg",
      "land-demo2",
      "index-main",
      "index-arch",
    ];

    document.body.classList.remove(...removeClasses);
  }, []);

  const nav_links = [
    {
      name: "Home",
      href: "#0",
    },
    {
      name: "Explore",
      href: "#0",
    },
    {
      name: "About",
      href: "#0",
    },
    {
      name: "Contact",
      href: "#0",
    },
  ];

  useEffect(() => {
    getCategoriesRequest();
    getVideosRequest();
    getHeaderRequest();
    getAboutRequest();
    getFooterRequest();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    getCategoriesRequest,
    getVideosRequest,
    getHeaderRequest,
    getFooterRequest,
  ]);

  return (
    <AppLayout type="nft-market" links={nav_links}>
      <Header />
      <Explore />
      <About />
      <Footer />
    </AppLayout>
  );
};

export default connect(({ data }) => ({ data }), {
  getCategoriesRequest,
  getVideosRequest,
  getHeaderRequest,
  getAboutRequest,
  getFooterRequest,
})(Home);
