import React from "react";
import { useState } from "react";
import { FaRegHeart, FaHeart } from "react-icons/fa";
import ReactPlayer from "react-player";

const Card = ({ data }) => {
  const [favorite, setFavorite] = useState(false);
  return (
    <div className={`col-lg-3 col-md-6 "mt-30"}`}>
      <div className="item-card md-mb50">
        <div className="img">
          <ReactPlayer url={data} playing={false} width="100%" height="220px" />
          <div className="fav" onClick={() => setFavorite(!favorite)}>
            {favorite ? <FaHeart color="#ff7961" /> : <FaRegHeart />}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Card;
