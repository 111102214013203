import AboutActionTypes from "./about.types";

export const getAboutRequest = () => ({
  type: AboutActionTypes.FETCH_ABOUT_REQUEST,
});

export const getAboutSuccess = (data) => ({
  type: AboutActionTypes.FETCH_ABOUT,
  payload: data,
});

export const getAboutFailed = (error) => ({
  type: AboutActionTypes.FETCH_ABOUT,
  payload: error,
});
