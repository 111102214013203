import { connect } from "react-redux";
import { FaBars } from "react-icons/fa";

const Navbar = (props) => {
  const {
    headerData,
    headerLoaded,
    links,
    navbarRef,
    theme,
    logoTheme,
    container,
  } = props;

  const goToSection = (e, link) => {
    if (link === "#0") e.preventDefault();

    const section = document.querySelector(
      `[data-scroll-index="${e.target.dataset.scrollNav}"]`
    );
    if (section) {
      section.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };
  return (
    <nav className={`navbar navbar-expand-lg ${theme}`} ref={navbarRef}>
      <div className={`container${container === "nft-market" ? "-xxl" : ""}`}>
        <a className="navbar-brand" href="/">
          <div className="logo">
            {logoTheme === "dark" && (
              <img src={headerLoaded ? `${headerData.logo.url}` : ""} alt="" />
            )}
            {logoTheme === "light" && (
              <img src={headerLoaded ? `${headerData.logo.url}` : ""} alt="" />
            )}
            {!logoTheme && (
              <img src={headerLoaded ? `${headerData.logo.url}` : ""} alt="" />
            )}
          </div>
        </a>

        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <FaBars />
        </button>

        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav">
            {links &&
              links.length &&
              links.map((link, idx) => (
                <li className="nav-item" key={idx}>
                  <a
                    className="nav-link"
                    href={link.href}
                    data-scroll-nav={idx}
                    onClick={(e) => goToSection(e, link.href)}
                  >
                    {link.name}
                  </a>
                </li>
              ))}
          </ul>
        </div>
      </div>
    </nav>
  );
};

const mapStateToProps = (state) => {
  return {
    headerData: state.header.header[0],
    headerLoaded: state.header.isLoaded,
  };
};

export default connect(mapStateToProps)(Navbar);
