import { FaFire } from "react-icons/fa";
import { connect } from "react-redux";

const About = (props) => {
  const { aboutData, aboutLoaded } = props;

  return (
    <section className="blck-nft section-padding" data-scroll-index="2">
      <div className="container-xxl">
        {aboutLoaded ? (
          <div className="row">
            <div className="col-lg-5">
              <div className="content md-mb80">
                <span className="rad-head mb-20">
                  <FaFire /> About Us
                </span>
                <p>{aboutData.description}</p>
              </div>
            </div>
            <div className="col-lg-6 offset-lg-1">
              <div className="imgs flex">
                {aboutData.images.map((image, i) => (
                  <div className={`img${i + 1}`} key={i}>
                    <img src={image.url} alt="" />
                    <span className="bord"></span>
                  </div>
                ))}
                <div className="box-text">
                  <div className="users-imgs flex valign">
                    <div>
                      {aboutData.subImages.map((image, i) => (
                        <div className={`simg${i + 1}`} key={i}>
                          <img src={image.url} alt="" />
                        </div>
                      ))}
                    </div>
                    <div className="ml-15 fz-12">
                      <span>
                        More Than <br />{" "}
                        <span className="fz-14 fw-700">
                          {aboutData.nrManagers}+
                        </span>
                      </span>
                    </div>
                  </div>
                  <p className="fz-14 text-light mt-10">
                    {aboutData.subdescription}
                  </p>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div>Loading...</div>
        )}
      </div>
    </section>
  );
};

const mapStateToProps = (state, ownProps) => {
  return {
    aboutData: state.about.about[0],
    aboutLoaded: state.about.isLoaded,
  };
};

export default connect(mapStateToProps)(About);
