import CategoriesActionTypes from "./categories.types";

export const getCategoriesRequest = () => ({
  type: CategoriesActionTypes.FETCH_CATEGORIES_REQUEST,
});

export const getCategoriesSuccess = (data) => ({
  type: CategoriesActionTypes.FETCH_CATEGORIES,
  payload: data,
});

export const getCategoriesFailed = (error) => ({
  type: CategoriesActionTypes.FETCH_CATEGORIES,
  payload: error,
});
