import { getHeaderFromFirebase } from "../../firebase-utils/firebase-utils";
import { put, takeEvery, fork } from "redux-saga/effects";
import { getHeaderSuccess, getHeaderFailed } from "./header.action";
import HeaderActionTypes from "./header.types";

function* fetchHeaderAsync() {
  try {
    const header = getHeaderFromFirebase();
    yield put(getHeaderSuccess(header));
  } catch (error) {
    yield put(getHeaderFailed(error.message));
  }
}

function* fetchHeaderStart() {
  yield takeEvery(HeaderActionTypes.FETCH_HEADER_REQUEST, fetchHeaderAsync);
}

const HeaderSaga = [fork(fetchHeaderStart)];

export default HeaderSaga;
