import { useEffect, useState } from "react";
import { FadeInOut, Card } from "../";
import { FaVideo } from "react-icons/fa";
import { capitalizeFirstLetter } from "../../utils/utils";
import { connect } from "react-redux";

const Explore = (props) => {
  const { videosData, categoriesData, videosLoaded, categoriesLoaded } = props;
  const [videos, setVideos] = useState();

  useEffect(() => {
    setVideos([
      {
        id: 1,
        category: 1,
        show: true,
        videoId: videosData.filter((video) => video.category === 1),
      },
    ]);
    openTab(1);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [videosData]);

  const openTab = (tabId) => {
    if (categoriesLoaded && videosLoaded) {
      // Remove class 'current' from all tab-links
      document
        .querySelectorAll(`.explore .item-link[data-tab]`)
        .forEach((el) => el.classList.remove("current"));
      // Add class 'current' to clicked tab-link
      document
        .querySelector(`.explore .item-link[data-tab="tab-${tabId}"]`)
        .classList.add("current");

      setVideos([
        {
          id: tabId,
          category: tabId,
          show: true,
          videoId: videosData.filter((video) => video.category === tabId),
        },
      ]);
    }
  };
  return (
    <section className="explore tabs-fade" data-scroll-index="1">
      <div className="container-xxl">
        <div className="row">
          <div className="col-12">
            <div className="sec-head-bord mb-50">
              <div className="container-xxl">
                <div className="row">
                  <div className="col-12 rest flex md-no-flex">
                    <div className="sec-head mr-20 md-mb30">
                      <h4>
                        <span className="icon">
                          <FaVideo />
                        </span>
                        Portfolio
                      </h4>
                    </div>
                    <div className="ml-auto">
                      <div className="tab-links">
                        <ul className="rest flex">
                          {categoriesLoaded ? (
                            categoriesData &&
                            categoriesData
                              .sort((a, b) => (a.id > b.id ? 1 : -1))
                              .map((tab, i) => (
                                <li
                                  className={`item-link ${
                                    i === 0 ? "current" : ""
                                  }`}
                                  data-tab={`tab-${tab.id}`}
                                  onClick={() => openTab(tab.id)}
                                  key={i}
                                >
                                  <span>
                                    {capitalizeFirstLetter(tab.title)}
                                  </span>
                                </li>
                              ))
                          ) : (
                            <div>Categories Loading...</div>
                          )}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            {videosLoaded ? (
              videos &&
              videos.map((tab) => (
                <FadeInOut key={tab.id} show={tab.show}>
                  <div
                    className="tab-content feat-card current"
                    id={`tab-${tab.id}`}
                  >
                    <div className="row">
                      {!!tab.videoId.length > 0
                        ? tab.videoId.map((cardData, idx) => (
                            <Card data={cardData.url} key={idx} />
                          ))
                        : ""}
                    </div>
                  </div>
                </FadeInOut>
              ))
            ) : (
              <div>Videos Loading...</div>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

const mapStateToProps = (state, ownProps) => {
  return {
    videosData: state.videos.videos,
    categoriesData: state.categories.categories,
    videosLoaded: state.videos.isLoaded,
    categoriesLoaded: state.categories.isLoaded,
  };
};

export default connect(mapStateToProps)(Explore);
