import { createStore, applyMiddleware } from "@reduxjs/toolkit";
import logger from "redux-logger";
import createSagaMiddleware from "redux-saga";
import promise from "redux-promise-middleware";

import rootReducer from "./rootReducer";
import rootSagas from "./rootSagas";

const sagaMiddleware = createSagaMiddleware();

const middlewares =
  process.env.NODE_ENV === "development"
    ? [sagaMiddleware, logger, promise]
    : [sagaMiddleware, promise];

export const store = createStore(rootReducer, applyMiddleware(...middlewares));

sagaMiddleware.run(rootSagas);
