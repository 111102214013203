import { combineReducers } from "@reduxjs/toolkit";
import categoriesReducer from "./categories/categories.reducer";
import videosReducer from "./videos/videos.reducer";
import headerReducer from "./header/header.reducer";
import aboutReducer from "./about/about.reducer";
import footerReducer from "./footer/footer.reducer";

const rootReducer = combineReducers({
  categories: categoriesReducer,
  videos: videosReducer,
  header: headerReducer,
  about: aboutReducer,
  footer: footerReducer,
});

export default rootReducer;
