import VideosActionTypes from "./videos.types";

export const getVideosRequest = () => ({
  type: VideosActionTypes.FETCH_VIDEOS_REQUEST,
});

export const getVideosSuccess = (data) => ({
  type: VideosActionTypes.FETCH_VIDEOS,
  payload: data,
});

export const getVideosFailed = (error) => ({
  type: VideosActionTypes.FETCH_VIDEOS,
  payload: error,
});
