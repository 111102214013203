import { getVideosFromFirebase } from "../../firebase-utils/firebase-utils";
import { put, takeEvery, fork } from "redux-saga/effects";
import { getVideosSuccess, getVideosFailed } from "./videos.action";
import VideosActionTypes from "./videos.types";

function* fetchVideosAsync() {
  try {
    const videos = getVideosFromFirebase();
    yield put(getVideosSuccess(videos));
  } catch (error) {
    yield put(getVideosFailed(error.message));
  }
}

function* fetchVideosStart() {
  yield takeEvery(VideosActionTypes.FETCH_VIDEOS_REQUEST, fetchVideosAsync);
}

const videosSaga = [fork(fetchVideosStart)];

export default videosSaga;
