import { connect } from "react-redux";

const Footer = (props) => {
  const { footerData, footerLoaded } = props;

  return (
    <footer
      className="bg-img pb-0"
      style={{ backgroundImage: "url('nft/img/space.jpg')" }}
      data-scroll-index="3"
    >
      <div className="container cal-action">
        {footerLoaded ? (
          <div className="row">
            <div className="col-lg-9">
              <div>
                <div className="text md-mb50">
                  <h2>{footerData.title}</h2>
                </div>
              </div>
            </div>
            <div className="col-lg-3 valign d-flex justify-content-end">
              <div>
                <a href={footerData.discord}>
                  <div className="butn butn-md bg-light text-dark radius-30">
                    <span className="text slide-up">Join Now</span>
                    <span className="text slide-down">Join Now</span>
                  </div>
                </a>
                <br className="md-hide" />
              </div>
            </div>
          </div>
        ) : (
          <div>Loading Footer...</div>
        )}
      </div>
      <div className="sub-footer pt-40 pb-40">
        <div className="container-xxl">
          <div className="row">
            <div className="col-lg-6">
              <div className="horizontal-link fz-13">
                <ul className="rest">
                  <li className="mr-30">
                    <a href="./">Privacy policy</a>
                  </li>
                  <li className="mr-30">
                    <a href="./">Legal notice</a>
                  </li>
                  <li className="mr-30">
                    <a href="./">Terms of service</a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="copyrights d-flex justify-content-end">
                <p className="fz-13">© 2022 VideoDawn. All rights reserved</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

const mapStateToProps = (state, ownProps) => {
  return {
    footerData: state.footer.footer[0],
    footerLoaded: state.footer.isLoaded,
  };
};

export default connect(mapStateToProps)(Footer);
