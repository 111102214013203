import { getFooterFromFirebase } from "../../firebase-utils/firebase-utils";
import { put, takeEvery, fork } from "redux-saga/effects";
import { getFooterSuccess, getFooterFailed } from "./footer.action";
import FooterActionTypes from "./footer.types";

function* fetchFooterAsync() {
  try {
    const footer = getFooterFromFirebase();
    yield put(getFooterSuccess(footer));
  } catch (error) {
    yield put(getFooterFailed(error.message));
  }
}

function* fetchFooterStart() {
  yield takeEvery(FooterActionTypes.FETCH_FOOTER_REQUEST, fetchFooterAsync);
}

const footerSaga = [fork(fetchFooterStart)];

export default footerSaga;
