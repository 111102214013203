import VideosActionTypes from "./videos.types";

const INITIAL_STATE = {
  videos: [],
  isFetching: false,
  isLoaded: false,
  errorMessage: undefined,
};

const videosReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case VideosActionTypes.FETCH_VIDEOS_REQUEST:
      return { ...state, isFetching: true, isLoaded: false };

    case VideosActionTypes.FETCH_VIDEOS_FULFILLED:
      return {
        ...state,
        videos: action.payload,
        isFetching: false,
        isLoaded: true,
      };

    case VideosActionTypes.FETCH_VIDEOS_REJECTED:
      return {
        ...state,
        errorMessage: action.payload,
        isFetching: false,
        isLoaded: false,
      };

    default:
      return state;
  }
};

export default videosReducer;
